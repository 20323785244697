export default class SubpageHead {
  constructor() {
    this.elements = [...document.querySelectorAll('[data-bg-fixed]')]
    if(!this.elements) return;
    this._onScroll();
  }

  _onScroll () {
    window.addEventListener('scroll', () => {
      const y = window.scrollY;
      this.elements.forEach(element => {
        element.style.top = `-${y}px`;
      });
    },{passive: true});
  }
}
