import Swiper from'swiper/bundle';

import Loader from '@webpack/imports/ui/Loader';
import Menu from '@webpack/imports/ui/Menu';
import Slider from '@webpack/imports/ui/Slider';
import SubpageHead from '@webpack/imports/ui/SubpageHead';
import HTMLAddClass from '@webpack/imports/ui/HTMLAddClass';

window.Swiper = Swiper

window.addEventListener('DOMContentLoaded', () => {
  new Loader();
  new Menu();
  new Slider();
  new SubpageHead();
  new HTMLAddClass();
});

