import { dom } from '@webpack/imports/core/'

export default class Loader {
  get _classes() {
    return {
      isLoading: 'is-loading',
      isLoaded: 'is-loaded',
    }
  }
  constructor() {
    this.loader = document.querySelector('[data-loader]')
    if(!this.loader) return;
    this._loader();
  }

  _loader() {
    dom.html.classList.add(this._classes.isLoading);

    window.addEventListener('load', () => {
      dom.html.classList.add(this._classes.isLoaded);
      setTimeout(() => {
        dom.html.classList.remove(this._classes.isLoading);
      });
    });
  }
}