import { detect, dom } from '@webpack/imports/core/'

export default class HTMLAddClass {
  get _classes() {
    return {
      isSafari: 'safari',
    }
  }

  constructor() {
    this._addClass()
  }

  _addClass() {
    if (detect.isSafari) {
      dom.html.classList.add(this._classes.isSafari);
    }
  }
}
