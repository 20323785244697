import matchMedia from '@webpack/imports/helpers/matchMedia'
import { config } from '@webpack/imports/core'

export default class Slider {
  get _classes() {
    return {
      isLoaded: 'is-slider-loaded',
    }
  }

  get _defaultOptions() {
    return {
      wrapperClass: 'slides',
      slideClass: 'slide',
      slideActiveClass: 'is-selected',
      slidesPerView: 'auto',
    }
  }

  constructor() {
    this.heroThumbsSlider = document.querySelector('[data-hero-thumbs-slider]')
    this.heroInfoSlider = document.querySelector('[data-hero-info-slider]')
    this.editorSlider = document.querySelector('[data-editor-slider]')
    this.spSlider = [...document.querySelectorAll('[data-sp-slider]')]

    window.addEventListener('load', () => this._initHeroSlider());
    this._initHditorSlider();
    this._spSlider();
  }

  _initHeroSlider() {
    if(!this.heroThumbsSlider && !this.heroInfoSlider) return;
    const heroThumbsSlider = new Swiper(this.heroThumbsSlider, {
      ...this._defaultOptions,
      spaceBetween: '6.34%',
      loop: true,
      speed: 600,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      breakpoints: {
        769: {
          spaceBetween: '8.33%',
          centeredSlides: true,
        },
        1024: {
          spaceBetween: '8.33%',
          centeredSlides: false,
        },
      },
      pagination: {
        el: '.hero-thumbs-pager',
        bulletActiveClass: 'is-selected',
        clickable: true,
        renderBullet: function (index, className) {
          const num = ('00' + (Number(index) + 1)).slice(-2);
          return '<li class="' + className + ' hero-thumbs-pager-item"><span class="hero-thumbs-pager-num">' + num + '</span><svg class="hero-thumbs-pager-progress-bg shape-torus"><use xlink:href="#shape-circle"/></svg><svg class="hero-thumbs-pager-progress shape-torus"><use xlink:href="#shape-circle"/></svg></li>';
        },
      },
    });

    const heroInfoSlider = new Swiper(this.heroInfoSlider, {
      ...this._defaultOptions,
      loop: true,
      loopAdditionalSlides: 5,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
    });

    heroThumbsSlider.controller.control = heroInfoSlider;
    heroInfoSlider.controller.control = heroThumbsSlider;

    this.heroThumbsSlider.classList.add(this._classes.isLoaded);
    setTimeout(() => this.heroThumbsSlider.classList.remove(this._classes.isLoaded), 5300);
  }

  _initHditorSlider() {
    if(!this.editorSlider) return;
    return new Swiper(this.editorSlider, {
      ...this._defaultOptions,
      loop: true,
      loopAdditionalSlides: 1,
      centeredSlides: true,
      spaceBetween: '6.66%',
      breakpoints: {
        769: {
          spaceBetween: 50,
        }
      },
    });
  }

  _spSlider() {
    if(!this.spSlider) return;

    matchMedia(config.breakPoint.sp, (matches) => {
      if (matches) { 
        this.spSlider.forEach(element => {
          return new Swiper(element, {
            ...this._defaultOptions,
            wrapperClass: 'article-list',
            slideClass: 'article-item',
          });
        });
      }
    })
  }

}
