const config = {
  viewport: {
    sm: '767',
    md: '768',
  },
  breakPoint: {
    sp: '(max-width: 767px)',
    pc: '(min-width: 768px)',
    landscape: '(orientation: landscape)',
  },
}

export default config