import { dom } from '@webpack/imports/core/'
import { BodyScrollLock } from '@webpack/imports/modules/BodyScrollLock';

export default class Menu {
  get _classes() {
    return {
      isOpened: 'is-menu-opened',
    }
  }

  constructor() {
    this.menu = document.querySelector('[data-menu]')
    if(!this.menu) return;

    this.trigger = document.querySelector('[data-menu-trigger]')
    this.close = [...document.querySelectorAll('[data-menu-close]')]

    this._toggle();
    this._onCloseClicked();
  }

  _toggle() {
    this.trigger.addEventListener('click', () => {
      dom.html.classList.contains(this._classes.isOpened) ? this.hide() : this.show()
    });
  }

  show() {
    BodyScrollLock(true);
    dom.html.classList.add(this._classes.isOpened);
  }

  hide() {
    BodyScrollLock(false);
    dom.html.classList.remove(this._classes.isOpened);
  }

  _onCloseClicked() {
    this.close.forEach(element => {
      element.addEventListener('click', () => this.hide());
    });
  }
}